import $ from 'jquery';

$(document).ready(function () {
    var lang = {
        de: {
            PLEASE_CHOOSE: 'Bitte wählen',
            CHOOSE: 'auswählen',
            CHOOSE_ALL: 'Alle auswählen',
            CHOSEN: 'ausgewählt',
            ALL: 'Alle',
            SEARCH: 'Suche',
            NO_RESULTS_FOR: 'Keine Ergebnisse für ',
            OK: 'Anwenden',
            CANCEL: 'Abbrechen',
            CLEAR_ALL: 'Alle zurücksetzen',
        },
        en: {
            PLEASE_CHOOSE: 'Please choose',
            CHOOSE: 'choose',
            CHOOSE_ALL: 'Choose all',
            CHOSEN: 'chosen',
            ALL: 'All',
            SEARCH: 'Search',
            NO_RESULTS_FOR: 'No results for ',
            OK: 'OK',
            CANCEL: 'Cancel',
            CLEAR_ALL: 'Clear all',
        },
    };

    lang = lang[$('html').attr('lang').substring(0, 2) || 'de'];

    $('select.js-sumo-select').each(function () {
        var $this = $(this);

        $this.SumoSelect({
            captionFormat: '{0} ' + lang['CHOSEN'],
            captionFormatAllSelected: $this.attr('data-choose-all') || lang['CHOOSE_ALL'],
            floatWidth: 0,
            forceCustomRendering: false,
            noMatch: lang['NO_RESULTS_FOR'] + ' "{0}"',
            placeholder: $this.attr('placeholder') || lang['PLEASE_CHOOSE'],
            search: $this.attr('data-search') || false,
            searchText: lang['SEARCH'],
            selectAll: $this.attr('data-select-all') || false,
            triggerChangeCombined: $this.attr('data-triggerChangeCombined') || false,
            locale: [lang['OK'], lang['CANCEL'], $this.attr('data-choose-all') || lang['CHOOSE_ALL'], lang['CLEAR_ALL']],
            nativeOnDevice: ['Android', 'BlackBerry', 'iPhone', 'iPad', 'iPod', 'Opera Mini', 'IEMobile', 'Silk'],
            okCancelInMulti: $this.attr('data-ok-cancel-in-multi') || false,
            clearAll: $this.attr('data-clear-all') || false,
            closeAfterClearAll: $this.attr('data-close-after-clear-all') || false,
        });
    });

    /*
     *
     * Select Submit On Change
     *
     * The option value is a key like in normal forms
     *
     */
    $('select.js-submit-onchange').on('change', function () {
        $(this).parents('form').submit();
    });

    /*
     *
     * Select Pageload On Change
     *
     * The option value is a url link
     *
     */
    $('select.js-pageload-onchange').on('change', function () {
        jumpMenu('parent', this, 0);
    });

    /*
     *
     * Disable Submit button after successfully validation
     * and show a spinner icon instead of the button text/icon
     */
    var formSubmitElem = document.querySelector('[data-js-selector="form-submit"]');
    var formErrorMessage = document.getElementById('form-error');

    if ($('form[data-validate]').length > 0) {
        $('form[data-validate]')
            .parsley()
            .on('form:validate', function (formInstance) {
                if (formInstance.isValid()) {
                    //
                    formSubmitElem.removeAttribute('type');
                    formSubmitElem.setAttribute('disabled', 'disabled');
                    formSubmitElem.classList.add('c-button--loading');
                } else {
                    if (formErrorMessage) {
                        formErrorMessage.removeAttribute('hidden');
                        window.scrollTo({
                            top: formErrorMessage.offsetTop - 140,
                            left: 0,
                            behavior: 'smooth',
                        });
                        formErrorMessage.focus();
                    }
                }
            });
    }

    $('select.js-sumo-select').on('change', function () {
        if (
            $('#' + $(this).attr('id'))
                .parent()
                .parent()
                .hasClass('parsley-error')
        ) {
            $('form[data-validate]').parsley().validate();
        }
    });

    // -----------------------------------------
    //
    // Parsley Error List
    //
    // -----------------------------------------

    // Erstelle Error Liste
    if (!document.getElementById('error-list')) {
        formErrorMessage.setAttribute('role', 'alert');
        formErrorMessage.setAttribute('aria-live', 'assertive');
        formErrorMessage.setAttribute('aria-atomic', 'true');

        const errorList = document.createElement('ul');
        errorList.id = 'error-list';

        document.querySelector('#form-error .c-notice__body').append(errorList);
    }

    // Fehler anzeigen, wenn das Formular validiert wird
    window.Parsley.on('form:error', function (formInstance) {
        const errorList = document.getElementById('error-list');

        // Liste leeren
        errorList.innerHTML = '';

        // Alle Fehlerfelder durchgehen
        formInstance.fields.forEach(function (field) {
            if (!field.isValid()) {
                const fieldErrors = field.getErrorsMessages();
                const fieldId = field.$element[0].id;
                const fieldLabel =
                    field.$element.attr('data-parsley-label') ||
                    document.querySelector('label[for="' + fieldId + '"]')?.textContent ||
                    field.$element[0].name ||
                    'Feld';

                // Wenn ein Feld einen Fehler hat
                field.$element.attr('aria-invalid', 'true');
                field.$element.attr('aria-describedby', 'error-for-' + fieldId);

                // Für jeden Fehlermeldung einen Listeneintrag erstellen
                fieldErrors.forEach(function (error) {
                    const listItem = document.createElement('li');
                    const link = document.createElement('a');
                    const errorId = 'error-for-' + fieldId;

                    link.href = '#' + fieldId;
                    link.textContent = fieldLabel + ': ' + error;
                    link.onclick = function () {
                        field.$element[0].focus();
                    };

                    listItem.id = errorId;
                    listItem.appendChild(link);
                    listItem.setAttribute('data-field-id', fieldId);
                    errorList.appendChild(listItem);
                });
            }
        });

        // Container nur anzeigen, wenn Fehler vorhanden sind
        formErrorMessage.style.display = errorList.children.length ? 'block' : 'none';
    });

    // Fehler entfernen, wenn ein Feld korrigiert wird
    window.Parsley.on('field:success', function (field) {
        const fieldId = field.$element[0].id;
        const errorList = document.getElementById('error-list');

        // Alle Listeneinträge des Feldes entfernen
        const errorItems = errorList.querySelectorAll('li[data-field-id="' + fieldId + '"]');
        errorItems.forEach(function (item) {
            item.remove();
        });

        field.$element.attr('aria-invalid', 'false');
        field.$element.removeAttr('aria-describedby');

        // Container ausblenden, wenn keine Fehler mehr vorhanden sind
        formErrorMessage.style.display = errorList.children.length ? 'block' : 'none';
    });
});

//$(window).resize(updateTeaserHeadlineMinHeight());

function jumpMenu(targ, selObj, restore) {
    window.location = '//' + window.location.hostname + selObj.options[selObj.selectedIndex].value;
    if (restore) selObj.selectedIndex = 0;
}

// -----------------------------------------
//
// Expand accordion if it has an active filter
//
// -----------------------------------------

(function () {
    var callback = function () {
        var allAccordionHeadingElems = document.querySelectorAll('.js-accordion__header');

        if (!allAccordionHeadingElems.length) {
            return;
        }

        Array.prototype.forEach.call(allAccordionHeadingElems, function (accordionHeadingElem) {
            var allActiveFilterElems = accordionHeadingElem.nextElementSibling.querySelectorAll('.is-current');

            if (allActiveFilterElems.length) {
                // accordionHeadingElem.setAttribute('tabindex', 0);
                // accordionHeadingElem.setAttribute('aria-selected', 'true');
                accordionHeadingElem.setAttribute('aria-expanded', 'true');
                accordionHeadingElem.classList.add('h-is-expanded');

                var accordionPanelElem = accordionHeadingElem.nextElementSibling;

                // console.log('accordionPanelElem', accordionPanelElem);
                // accordionPanelElem.setAttribute('aria-hidden', 'false');
                // accordionPanelElem.style.height = 'auto';
                accordionPanelElem.classList.add('h-is-expanded');
            }
        });
    };

    if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
})();
